export enum RouteName {
  activityView = '/activity',
  auctionsView = '/auctions',
  home = '/',
  marketplace = '/marketplace',
  marketplaceMint = '/marketplace/:tokenMint',
  sell = '/sell',
  drop = '/drop',
  customToken = '/custom-token-marketplace',
  multipleCollection = '/multi-collection-marketplace',
  marketplaceWithUrl = '/marketplace-with-url',
  klausenart = 'klausenart',
  news = 'news',
  gallery = 'gallery',
  roadmap = 'roadmap',
  nfts = 'nfts',
  pfps = 'pfps',
  digitalCollectibles = 'digitalCollectibles',
  digitalArt = 'digitalArt',
  token = 'token',
  info = 'info',
  tokenKart = 'tokenKart',
  tokenomics = 'tokenomics'
  
}
